import React, { useContext, useEffect } from 'react'
import LocaleContext from '../../context/LocaleContext'
import PageContext from '../../context/PageContext'
import { Locale, localisePath } from '../../domain/locale'
import { CART_PATH } from '../../domain/path'
import Layout from '../../components/layout/Layout'
import Seo from '../../components/layout/Seo'
import Cart from '../../components/cart/Cart'

function CartPage() {
    const { setLocale } = useContext(LocaleContext)

    useEffect(() => {
        setLocale(Locale.SG)
    }, [])

    return (
        <PageContext.Provider
            value={{
                basepath: CART_PATH,
                locale: Locale.SG,
                localised: true,
            }}
        >
            <Layout>
                <Seo
                    title="Cart"
                    titleCompany="Telobag SG"
                    relativeUrl={localisePath(CART_PATH, Locale.SG)}
                />

                <div className="mb-6">
                    <h1 className="font-bold text-3xl md:text-5xl mb-3 text-primary">
                        Cart
                    </h1>

                    <hr className="border-b border-gainsboro w-10" />
                </div>

                <Cart />
            </Layout>
        </PageContext.Provider>
    )
}

export default CartPage
